.button {
  /*background-color: #4CAF50; /* Green */
  background-color: #eeca2a; /* Green */
  border: none;
  color: black;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  width: 225px;
}