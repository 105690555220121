.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#constellations-bg {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: transparent;
  z-index: -2;
  background: linear-gradient(to bottom, #32081A 0%, #322E38 50%, #32081A 100%);
  position: fixed;
}

#constellations {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: transparent;
  z-index: -1;
  position: absolute;
  opacity: .8;
}

a {
  color: #CD9A37;
}
a:hover, a:focus {
  color: #908D95;
}
.videoplayer {
  max-width: 100%;
}

.policypagetext{
  color: #CD9A37;
}