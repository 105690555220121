.button {
    background: linear-gradient(135deg, #BD881E, #CD9A37, #644912); /* gold */
    color: #3A2309;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    width: 245px;
    border-radius: 5px;
    transition: all ease-in-out 250ms;
    border: 1px solid #3A2309;
    margin: 20px;
}

.button:hover, .button:active, .button:focus {
    background: linear-gradient(135deg, #4D340F, #614610, #3A2309);
    color: #D9AA45;
    border-color: #D9AA45;
}