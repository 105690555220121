.option {
    display: flex;
    padding: 10px;
    align-items: center;
    justify-content: center;
    align-content: center;
    text-align: center;
    border-radius: 5px;
    background: linear-gradient(135deg, #BD881E, #CD9A37, #644912);
    border: 1px solid #3A2309;
    color: #3A2309;
    cursor: pointer;
    font-weight: bold;
}

.option:last-child {
    margin: 0;
}

.option:hover,
.option:focus,
.selected-option {
    background: linear-gradient(135deg, #4D340F, #614610, #3A2309);
    border-color: #D9AA45;
    cursor: pointer;
    color: #D9AA45;
}

.selected-option,
.selected-option:hover,
.selected-option:focus {
    cursor: default;
}

.options-container {
    display: flex;
    flex-direction: row;
    align-content: flex-start;
    flex-wrap: wrap;
    row-gap: 10px;
    column-gap: 10px;
    margin-bottom: 32px;
}

.optionText {
    text-align: center;
    margin: 0 auto;
}

.icon-container {
    width: 160px;
    height: 160px;
    border-radius: 80px;
    background: linear-gradient(135deg, #BD881E, #CD9A37, #644912);
    -webkit-box-shadow: 3px 3px 8px 3px rgba(0, 0, 0, 0.2);
    box-shadow: 3px 3px 8px 3px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
}

.token-title {
    text-align: left;
    color: #dedede;
    font-size: 28px;
    min-width: 300px;
}

.token-text {
    text-align: left;
    color: #dedede;
    font-size: 16px;
}

#token-address {
    font-size: 24px;
    font-weight: 400;
    color: #CD9A37;
    text-align: center;
}

@media only screen and (max-width: 600px) {
    #token-address {
        font-size: 14px;
    }

    .options-container {
        justify-content: center;
    }

    .icon-container {
        margin-bottom: 32px;
    }
}
