.road-map-node {
  width: 20px;
  height: 20px;
  border: 4px solid #CD9A37;
  border-radius: 12px;
}

.road-map-line {
  /* height: 300px; */
  width: 0px;
  border-right: 1px solid #CD9A37;
}

.road-map-dotted-line {
  width: 0px;
  border-right: 1px dotted #CD9A37;
}

.completed {
  background-color: #CD9A37;
}


.in-progress {
  background-color: #222;
  animation-name: color;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

.box{
  background-color: crimson;
  width: 100px;
  height: 100px;
  border: 4px solid white;
  border-radius: 12px;
}

@keyframes color {
  0% {
    background-color: #222;
  }
  50% {
    background-color: #CD9A37;
  }
  100% {
    background-color: #222;
  }
}

.road-map-description {
  width: 300px;
  text-align: left;
}

.textAreaColumn{
  width:100%;    
}
.textAreaColumn div{
  float:left;
  width:50%;
  padding:5px;
  box-sizing: border-box;
}

.textAreaColumn div span{
  display:block;
  text-align:center;
}

.textAreaColumn div textarea{
  box-sizing: border-box;
  width:100%;
  border:3px goldenrod;
  min-height:150px;
  background-color:goldenrod;
}

/* ul
{
float: left;
margin: 0 5px;
} */
